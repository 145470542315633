<template>
    <div class="main-page">
      <template v-if="showHeader">
        <section class="page-section mb-3">
          <div class="container-fluid">
            <div class="grid justify-content-between">
              <div class="col-12 md:col-6 lg:col-5">
                <div class="">
                  <div class="flex align-items-center">
                    <div>
                      <Avatar
                        size="large"
                        class="primary"
                        icon="pi pi-star"
                      />
                    </div>
                    <div class="ml-2">
                      <div class="text-2xl text-primary font-bold">
                        Update Application
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
      </template>
      <section class="page-section">
        <div class="container-fluid">
          <div class="grid">
            <div class="md:col-7 sm:col-12 comp-grid">
              <div class="">
                <div>
                  <template v-if="!loading">
                    <div class="grid">
                      <div class="col-12">
                        <form
                          ref="observer"
                          tag="form"
                          @submit.prevent="startRecordUpdate()"
                        >
                          <!--[form-content-start]-->
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                Approval Status *
                              </div>
                              <div id="ctrl-tertiary_certificate_verify_status-holder">
                                <Dropdown
                                  class="w-full"
                                  :class="getErrorClass('tertiary_certificate_verify_status')"
                                  optionLabel="label"
                                  optionValue="value"
                                  ref="ctrltertiary_certificate_verify_status"
                                  v-model="formData.tertiary_certificate_verify_status"
                                  :options="$menus.approval_statusItems"
                                  label="Approval Status"
                                  placeholder="Select ..."
                                >
                                </Dropdown>
                              </div>
                            </div>
                            <div class="col-12">
                            <div class="font-bold text-sm">
                              Comment *
                            </div>
                            <div class="mb-2 text-xs text-primary">Kindly provided comments on your decision</div>
                            <div id="ctrl-tertiary_certificate_verify_comments-holder">
                              <Textarea
                                :class="getErrorClass('tertiary_certificate_verify_comments')"
                                class="w-full"
                                ref="ctrltertiary_certificate_verify_comments"
                                rows="3"
                                v-model="formData.tertiary_certificate_verify_comments"
                                placeholder="Enter your comments here ..."
                                type="textarea"
                              >
                              </Textarea>
                            </div>
                          </div>
                          </div>
                          <!--[form-content-end]-->
                          <div v-if="showSubmitButton" class="text-center my-3">
                            <Button
                              type="submit"
                              label="Update"
                              icon="pi pi-send"
                              :loading="saving"
                            />
                          </div>
                        </form>
                        <slot :submit="submit" :saving="saving"></slot>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  <script>
  //[--PAGE-IMPORT-STATEMENT--]
  import { PageMixin } from "../../mixins/page.js";
  import { EditPageMixin } from "../../mixins/editpage.js";
  import { mapActions, mapGetters, mapState } from "vuex";
  import useVuelidate from "@vuelidate/core";
  import {
    required,
    email,
    sameAs,
    minLength,
    maxLength,
    minValue,
    maxValue,
    numeric,
    integer,
    decimal,
    ipAddress,
  } from "@vuelidate/validators";
  export default {
    setup() {
      return { v$: useVuelidate() };
    },
    name: "editapplicantsPage",
    components: {
      //[--PAGE-COMPONENT-NAME--]
    },
    mixins: [PageMixin, EditPageMixin],
    props: {
      pageName: {
        type: String,
        default: "applicants",
      },
      idName: {
        type: String,
        default: "applicant_id",
      },
      routeName: {
        type: String,
        default: "applicantsedit",
      },
      pagePath: {
        type: String,
        default: "applicants/edit",
      },
      apiPath: {
        type: String,
        default: "applicants/edit",
      },
    },
    data() {
      return {
        formData: {
            tertiary_certificate_verify_status: "",
            tertiary_certificate_verify_comments: "",
        },
        submitted: false,
      };
    },
    computed: {
      pageTitle: {
        get: function () {
          return "Update Record";
        },
      },
      currentRecord: {
        get: function () {
          return this.$store.getters["applicants/currentRecord"];
        },
        set: function (value) {
          this.$store.commit("applicants/setCurrentRecord", value);
        },
      },
    },
    validations() {
      let formData = {
        tertiary_certificate_verify_status: { required },
        tertiary_certificate_verify_comments: {},
      };
      return { formData };
    },
    methods: {
      ...mapActions("applicants", ["updateRecord", "fetchRecord"]),
      async startRecordUpdate() {
        this.submitted = true;
        const isFormValid = !this.v$.$invalid;
        if (!isFormValid) {
          this.flashMsg("Please complete the form", "Form is invalid", "error");
          return;
        }
        this.saving = true;
        let id = this.id;
        let url = this.apiUrl;
        let payload = this.normalizedFormData();
        let data = { id, url, payload };
        this.updateRecord(data).then(
          (response) => {
            this.saving = false;
            this.flashMsg(this.msgAfterUpdate);
            this.resetForm();
            this.closeDialogs(); // close page dialog that if opened
            if (this.redirect) this.navigateTo(`/applicants`);
          },
          (response) => {
            this.saving = false;
            this.showPageRequestError(response);
          }
        );
      },
      updateFormFields: function () {
        //update form fields value after load from api
        //e.g convert fieldvalue (value,value2,value2) to array
      },
      resetForm() {
        //reset form fields value
        this.formData = { tertiary_certificate_verify_status: "", tertiary_certificate_verify_comments: "" };
        //raise event to reset other custom form components
        //this.$EventBus.$emit("resetForm");
      },
      getErrorClass(field) {
        if (this.v$.formData[field].$invalid && this.submitted) {
          return "p-invalid";
        }
        return "";
      },
    },
    watch: {},
    async mounted() {},
    async created() {},
  };
  </script>
  <style scoped></style>
  <style scoped></style>
  